import React, { SyntheticEvent } from "react";
import { Flex, Spacer, Text } from "@chakra-ui/react";
import { Paragraph } from "../../components/Paragraph";
import { survivorClient } from "../../service/backend";
import Gears from "../../../assets/icons/gears.svg";
import Person from "../../../assets/icons/user.svg";
import Document from "../../../assets/icons/encrypted-form.svg";
import Consent from "../../../assets/icons/consent.svg";
import Resources from "../../../assets/icons/resources.svg";
import { DashboardCard } from "./DashboardCard";
import { PrivateRoutes } from "../../config/routes";
import { useNavigate } from "react-router-dom";

export const MainDashboard: React.FC = () => {
	const navigate = useNavigate();
	const numLogs =
		survivorClient.incidentLogs.length + survivorClient.records.length;

	const onClickEnterMatching = (e: SyntheticEvent) => {
		e.preventDefault();
		navigate(PrivateRoutes.MATCHING_SYSTEM);
	};

	const onClickEnterIncidentLog = (e: SyntheticEvent) => {
		e.preventDefault();
		navigate(PrivateRoutes.ILOG);
	};

	const onClickEnterAccountSettings = (e: SyntheticEvent) => {
		e.preventDefault();
		navigate(PrivateRoutes.ACCOUNT);
	};

	const onClickEnterConsentSettings = (e: SyntheticEvent) => {
		e.preventDefault();
		navigate(PrivateRoutes.CONSENT_SETTINGS);
	};

	const onClickEnterResources = (e: SyntheticEvent) => {
		e.preventDefault();
		navigate(PrivateRoutes.RESOURCES);
	};

	return (
		<Flex overflowY="visible" flexDir="column">
			<Text
				as="h1"
				fontSize="xl"
				fontWeight="bold"
				my="16px"
				textAlign="center"
			>
				Welcome to Callisto Vault
			</Text>

			<Paragraph>
				We are humbled that you have chosen to trust Callisto to help support
				you on your journey toward healing and justice, in whatever shape that
				takes for you. That may include our Matching System, our Incident Log,
				or utilizing our resources to get the support you need. We know that
				this process can be difficult. Take your time.
			</Paragraph>

			<Paragraph>
				<b>
					If you need to exit at any time, the Quick Exit button will take you
					to the Weather Channel. Be aware that if you use this button, you will
					lose any unsaved work.
				</b>
			</Paragraph>

			<Flex overflowY="visible" flexDir="column">
				<Flex flexDir={{ base: "column", md: "row" }}>
					{/* <DashboardCard
            title='Matching System'
            text={`${survivorClient.entries.length} Matching ${survivorClient.entries.length === 1 ? 'Entry' : 'Entries'}`}
            icon={Gears as string}
            buttonText='Enter Matching'
            isPrimary={true}
            onButtonClick={onClickEnterMatching}
          /> */}
					<DashboardCard
						title="Incident Log"
						text={`${numLogs} Incident ${numLogs === 1 ? "Log" : "Logs"}`}
						icon={Document as string}
						buttonText="Enter Incident Log"
						isPrimary={true}
						onButtonClick={onClickEnterIncidentLog}
					/>
				</Flex>
				<Flex flexDir={{ base: "column", md: "row" }}>
					<Spacer />
					<DashboardCard
						title="Account Settings"
						text={survivorClient.contactInfo?.name ?? survivorClient.username}
						icon={Person as string}
						buttonText="Enter Account Settings"
						isPrimary={true}
						onButtonClick={onClickEnterAccountSettings}
					/>
					<Spacer />
					<DashboardCard
						title="Consent Settings"
						text="See what you are consenting to"
						icon={Consent as string}
						buttonText="Enter Consent Settings"
						isPrimary={true}
						onButtonClick={onClickEnterConsentSettings}
					/>
				</Flex>
				<Flex flexDir={{ base: "column", md: "row" }}>
					<Spacer />
					<DashboardCard
						title="Resources"
						text="View resources available to Callisto Vault account holders"
						icon={Resources as string}
						buttonText="View Resources"
						isPrimary={true}
						onButtonClick={onClickEnterResources}
					/>
					<Spacer />
				</Flex>
			</Flex>
		</Flex>
	);
};
